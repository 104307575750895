import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import styled from '@emotion/styled';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import isValid from 'date-fns/isValid';

import closeIcon from '../images/close_cross.svg';
import headerImg from '../images/modals/modal_stay.png';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { COLORS } from '../constants';
import { FONT_GOTHAM_MEDIUM } from '../styles/fonts';

import { RichTextView, ActionButton, device } from './commonStyled';
import SectionTitle from './SectionTitle';
import { Modal } from './Modal';

const Img = styled.img`
    width: 100%;
    height: auto;
    background-color: #fafafd;
`;

const CloseIcon = styled.button`
    position: absolute;
    right: 24px;
    top: 24px;
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    padding: 2px;
    background: url(${closeIcon}) no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    transition: all 0.2s;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        border: none;
        outline: none;
        opacity: 1;
    }
`;

const Container = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 33px;
`;

const SectionTitleStyled = styled(SectionTitle)`
    margin-bottom: 32px;
`;

const RichTextViewStyled = styled(RichTextView)`
    margin-bottom: 48px;
    text-align: center;
`;

const Content = styled.div`
    padding: 32px 12px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const RedSpan = styled.span`
    color: ${COLORS.red};
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const ListOfBooks = styled.div`
    line-height: 30px;
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const HiddenElem = styled.span`
    position: fixed;
    top: 0;
    width: 100%;
    height: 30px;
    left: 0;
    z-index: 100000;

    @media ${device.laptopLarge} {
        height: 15px;
    }
`;

const StayModal = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLSpanElement>();
    const [timeShown, setTimeShown] = useLocalStorage(
        'nemkova-site-stay-shown'
    );

    const showModal = useCallback(() => {
        setIsVisible(true);
        setTimeShown(Date.now());
    }, [setTimeShown]);

    const hideModal = useCallback(() => setIsVisible(false), []);

    const isTimeToShowModal = useMemo(
        () =>
            !timeShown ||
            (isValid(timeShown) &&
                differenceInWeeks(new Date(timeShown), new Date()) >= 4),
        [timeShown]
    );

    useEffect(() => {
        let prev = 0;
        const showModalCb = (e) => {
            if (e.pageY < prev) {
                showModal();
                ref.current?.removeEventListener('mousemove', showModalCb);
            }

            prev = e.pageY;
        };

        if (isTimeToShowModal) {
            ref.current?.addEventListener('mousemove', showModalCb);
        }

        const lastRef = ref.current;

        return () => lastRef?.removeEventListener('mousemove', showModalCb);
    }, [isTimeToShowModal, showModal]);

    return (
        <>
            {isTimeToShowModal && <HiddenElem ref={ref} />}
            <Modal
                onClose={hideModal}
                isOpen={isVisible}
                modalHeight={623}
                modalWidth={750}
            >
                <Container>
                    <Img src={headerImg} />
                    <Content>
                        <SectionTitleStyled
                            fontSize={37}
                            title="Уже уходите?"
                        />
                        <RichTextViewStyled fontSize={37}>
                            Для вас есть <RedSpan>подарок</RedSpan>:
                            <br />
                            <br />
                            <ListOfBooks>
                                «Список книг, меняющих жизнь»
                            </ListOfBooks>
                        </RichTextViewStyled>
                        <ActionButton
                            href="https://mnlp.cc/mini?domain=nemkova&id=33"
                            target="_blank"
                            rel="nofollow"
                        >
                            Получить подарок
                        </ActionButton>
                    </Content>
                    <CloseIcon onClick={hideModal} />
                </Container>
            </Modal>
        </>
    );
};

export default StayModal;
