import React from 'react';
import { Helmet } from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import favicon from '../images/favicon/favicon.png';
import { Seo } from '../models';
import { useExternalScript } from '../hooks/useExternalStript';
import { isProdBuild, SCRIPT_JIVOSITE, SITE_URL } from '../constants';
import { applyYM, getAbsoluteImageUrl, getAbsolutePageUrl } from '../utils';
import { useGeneralSettings } from '../queries/useGeneralSettings';

interface HelmetComponentProps {
    seoMeta: Seo;
}

const HelmetComponent: React.FC<HelmetComponentProps> = ({ seoMeta = {} }) => {
    const seo: Seo | Record<string, never> = seoMeta;
    const generalSettings = useGeneralSettings();

    const parsedTitle = seo.title;

    useExternalScript('JIVO_SITE', SCRIPT_JIVOSITE);
    useExternalScript('YANDEX_METRIKA', null, applyYM);

    const url = getAbsolutePageUrl(seo.opengraphUrl);
    const title = parsedTitle || generalSettings.title;

    return (
        <>
            <Helmet title={title}>
                <html lang="ru" />
                {isProdBuild && <base href={SITE_URL} />}
                <link rel="icon" href={favicon} type="image/png" />
                <meta
                    name="google-site-verification"
                    content="fVGfZLIp-Nr0Lgb-Ew-iuZDf2zHnVbtiCjVBhIRNBXg"
                />
                <meta
                    name="google-site-verification"
                    content="1dUrSw5N_1koNWptoQaZFaAd4wc5v-B1gR1xJ9HEPI8"
                />
                <meta name="yandex-verification" content="e84bb2fb4f65759c" />
                <meta name="yandex-verification" content="921ad99736380c4e" />
                <meta name="yandex-verification" content="e84bb2fb4f65759c" />
                <meta name="theme-color" content="#fff" />
                <noscript>
                    {`<div>
						<img
							src="https://mc.yandex.ru/watch/62749984"
							style="position:absolute; left:-9999px;"
							alt=""
						/>
					</div>`}
                </noscript>
            </Helmet>
            <GatsbySeo
                title={title}
                description={
                    seo.opengraphDescription || generalSettings.description
                }
                canonical={url}
                openGraph={{
                    url,
                    title,
                    type: seo.opengraphType || 'website',
                    description:
                        seo.opengraphDescription || generalSettings.description,
                    images: [
                        {
                            url:
                                getAbsoluteImageUrl(seo?.opengraphImage) ||
                                '/logo.png',
                            alt: generalSettings.title,
                        },
                    ],
                    site_name: generalSettings.title,
                }}
            />
        </>
    );
};

export default HelmetComponent;
