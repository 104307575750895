import { useState } from 'react';

export function useLocalStorage(key, initialValue: any = null) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window?.localStorage.getItem(key);

            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);

            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window?.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (e) {
            console.log(e);
        }
    };

    return [storedValue, setValue];
}
