import { css } from '@emotion/react';

import GothamProMedium from './fonts/GothamPro-Medium.ttf';
import GothamPro from './fonts/GothamPro.ttf';
import GothamProLight from './fonts/GothamPro-Light.ttf';
import NicolettaScript from './fonts/Nicoletta script.ttf';
import TimesNewRoman from './fonts/times-new-roman.ttf';
import MontserratRegular from './fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from './fonts/Montserrat-SemiBold.ttf';
import MontserratBold from './fonts/Montserrat-Bold.ttf';

export const globalStyles = css`
    .glide__bullets {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -24px;
        display: none;
        justify-content: center;
        width: 100%;
        z-index: 2;

        @media (max-width: 1200px) {
            display: flex;
        }

        &-always-visible {
            display: block;
        }
    }

    .glide__slide {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    @font-face {
        font-family: 'GothamPro Medium';
        src: url('${GothamProMedium}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'GothamPro';
        src: url('${GothamPro}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'GothamPro Light';
        src: url('${GothamProLight}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Nicoletta';
        src: url('${NicolettaScript}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Times New Roman';
        src: url('${TimesNewRoman}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat-Regular';
        src: url('${MontserratRegular}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat-SemiBold';
        src: url('${MontserratSemiBold}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Montserrat-Bold';
        src: url('${MontserratBold}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    * {
        box-sizing: border-box;
    }

    html {
        overflow-y: scroll;
        height: 100%;
    }

    body {
        position: relative;
        padding: 0;
        margin: 0;
        background: #ffffff;
        width: 100%;
        height: 100%;
        font-family: 'GothamPro', Arial, sans-serif;
        color: #000000;
        font-size: 14px;
        min-width: 320px;
    }

    a {
        outline: none;
        text-decoration: none;
        color: #000000;
    }

    a:hover {
        color: #a60424;
    }

    h1,
    h2,
    h3 {
        font-weight: normal;
        font-family: 'GothamPro Medium';
        margin: 0;
    }

    b,
    strong {
        font-family: 'GothamPro Medium';
        font-weight: normal;
    }

    p {
        margin: 0;
        padding: 0 0 1rem 0;
    }

    .__jivoMobileButton {
        bottom: 70px !important;
    }

    .sliderCentered {
        .glide__track {
            position: relative;

            &:after {
                content: '';
                z-index: 1;
                position: absolute;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 30px;

                background: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.76) 76.08%,
                    rgba(255, 255, 255, 0.89) 88.6%,
                    #ffffff 100%
                );

                @media (max-width: 1024px) {
                    display: none;
                }
            }

            &:before {
                content: '';
                z-index: 1;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 30px;

                background: linear-gradient(
                    270deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.76) 76.08%,
                    rgba(255, 255, 255, 0.89) 88.6%,
                    #ffffff 100%
                );

                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }

    .glide {
        position: relative;
        width: 100%;
        box-sizing: border-box;

        &__track {
            overflow: hidden;
        }

        &__slides {
            position: relative;
            width: 100%;
            list-style: none;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            touch-action: pan-Y;
            overflow: hidden;
            padding: 0;
            white-space: nowrap;
            display: flex;
            flex-wrap: nowrap;
            will-change: transform;

            &#{$glide-modifier-separator}dragging {
                user-select: none;
            }
        }

        &__slide {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            white-space: normal;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-tap-highlight-color: transparent;

            a {
                user-select: none;
                -webkit-user-drag: none;
                -moz-user-select: none;
                -ms-user-select: none;
            }
        }

        &__arrows {
            -webkit-touch-callout: none;
            user-select: none;
        }

        &__bullets {
            -webkit-touch-callout: none;
            user-select: none;
        }

        &--rtl {
            direction: rtl;
        }
    }
`;
