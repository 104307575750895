import { useStaticQuery, graphql } from 'gatsby';

import { SharpImage, Seo, AvailableSections } from '../models';

interface MainPageHeaderData {
    main_logo_text: string;
    sub_logo_text: string;
    highlightedText: string;
    image: SharpImage;
    infoTitle: string;
    footer: string;
    footerImage: SharpImage;
    bulletPoints: {
        bulletPoint1: string;
        bulletPoint2: string;
        bulletPoint3: string;
    };
    action_block: {
        highlightedText: string;
        image: SharpImage;
        infoText: string;
        title: string;
        buttonLinkBttomBlock: string;
        buttonText: string;
    };
    action_block_middle: {
        highlightedText: string;
        image: SharpImage;
        infoText: string;
        title: string;
        buttonLink: string;
        buttonText: string;
    };
    seo: Seo;
    blocks: AvailableSections[];
}

export const useMainPageData = (): MainPageHeaderData => {
    const { wpPage } = useStaticQuery(
        graphql`
            {
                wpPage(common_acf: { id: { eq: "main" } }) {
                    seo {
                        ...Seo
                    }
                    common_acf {
                        blocks
                    }
                    main_page_acf {
                        main_logo_text: mainLogoText
                        sub_logo_text: subLogoText
                        footer
                        footerImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 800)
                                }
                            }
                        }
                        highlightedText: highlightedtext
                        image {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1200)
                                }
                            }
                        }
                        infoTitle: infotitle
                        bulletPoints: bulletpoints {
                            bulletPoint1: bulletpoint1
                            bulletPoint2: bulletpoint2
                            bulletPoint3: bulletpoint3
                        }
                        action_block: actionBlockBottom {
                            highlightedText: highlightedtext
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 800)
                                    }
                                }
                            }
                            infoText: infotext
                            title: title
                            buttonText: buttontext
                            buttonLinkBttomBlock: buttonlinkbttomblock
                        }
                        action_block_middle: actionBlockMiddle {
                            highlightedText: highlightedtext
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 800)
                                    }
                                }
                            }
                            infoText: infotext
                            title
                            buttonText: buttontext
                            buttonLink: buttonlink
                        }
                    }
                }
            }
        `
    );

    return {
        ...wpPage?.common_acf,
        ...wpPage?.main_page_acf,
        seo: wpPage?.seo,
    };
};
